import React from "react"
import { SideMenu } from "@fastly/consistently"
import FrontmatterPageContext from "~context/FakePageContext"

import { mainArea, main } from "~vanilla/layout-default.css"
import { sideMenu } from "~components/SideMenu/side-menu.css"

import LayoutCore from "./layout-core"

import { SideMenuSwitcher } from "~components/SideMenu/side-menu"

const WrapLayoutDefault = (props) => {
  const { frontmatter = {}, children } = props

  frontmatter["section_safe"] = frontmatter["section"] || "none"

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <SideMenu
              className={`${sideMenu} main-nav section-${frontmatter.section_safe}`}
              aria-label="Navigation between documents"
              data-swiftype-index="false"
            >
              <SideMenuSwitcher />
            </SideMenu>

            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              {children}
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default WrapLayoutDefault
