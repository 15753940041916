import * as React from "react"
import { graphql, Link } from "gatsby"
import WrapLayoutDefault from "~templates/wrap-default"
import CanaryIndexContent from "~partials/canary-index.mdx"

// markup
const CanaryIndexPage = ({data}) => {
  const pages = data.canaryPages.nodes.map((node) => {
    const { frontmatter } = node.pageContext

    // try to use the cleaner matchPath if it exists before just path
    let linkHref = node.matchPath ?? node.path ?? "/"
    let linkText = node.matchPath ?? node.path ?? "BROKEN"

    return (
      <li key={frontmatter.fileslug}>
        <Link to={linkHref}>{linkText.replace("/canary/","")}</Link>
      </li>
    )
  })

  let frontmatter = {
    lang: "en",
    section: "canary",
    section_safe: "canary",
  }

  return (
    <>
      <WrapLayoutDefault frontmatter={frontmatter}>
        <div className="content-content">

          <CanaryIndexContent />

          <hr />

          <h1>autolist</h1>
          <ul>{pages}</ul>

        </div>
      </WrapLayoutDefault>
    </>
  )
}

export default CanaryIndexPage

export const query = graphql`
query {
  canaryPages: allSitePage(
    sort: {
        fields: matchPath
      },
    filter: {
        path: {
          regex: "/^/canary/.+/"
        }
      }
  ) {
    nodes {
      path
      matchPath
      pageContext
    }
  }
}
`

export const Head = (props) => {
  return <>
    <title>Canary! </title>
    <meta name="robots" content="noindex"></meta>
  </>
}
